<template>
  <section>
    <section>
      <h2 class="mb-5">Progetti Utente</h2>
      <vue-good-table ref="projectTable" :columns="columns" :rows="project_list">
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field === 'status'" class="text-nowrap">
            <span v-if="props.row.project_status == 'draft'">In Bozza</span>
            <span v-else>Dati Confermati</span>
          </div>

          <div class="d-flex flex-row" v-else-if="props.column.field === 'action'">
            <b-dropdown id="project-actions" text="Azioni" :boundary="$refs.projectTable.$el">

              <form v-if="props.row.project_type == 1" ref="pdf"
                :action="BACKEND_BASE_URL + '/module/pdf.php?id=' + props.row.id_project" target="_blank" method="post"
                class="">
                <input type="hidden" :value="user_token" />
                <b-dropdown-item-button class="w-100" variant="primary" :type="'submit'"
                  v-on:click.prevent="openPdf($event)">Apri PDF</b-dropdown-item-button>
              </form>

              <b-dropdown-item-button v-if="props.row.project_type == '1' && props.row.project_status == 'draft'"
                class="w-100" variant="primary" :type="'submit'"
                v-on:click.prevent="makeReady($event, props.row.id_project, 1)">Conferma</b-dropdown-item-button>
              <b-dropdown-item-button v-if="props.row.project_type == '1' && props.row.project_status == 'ready'"
                class="w-100" variant="primary" :type="'submit'" v-on:click.prevent="makeDraft($event, props.row.id_project, 1)">Metti in
                bozza</b-dropdown-item-button>


              <form v-if="props.row.project_type == 2" ref="pdf"
                :action="BACKEND_BASE_URL + '/module_mantainer/pdf.php?id=' + props.row.id_project" target="_blank"
                method="post" class="">
                <input type="hidden" :value="user_token" />
                <b-dropdown-item-button class="w-100" variant="primary" :type="'submit'"
                  v-on:click.prevent="openPdf($event)">Apri PDF</b-dropdown-item-button>
              </form>
              <b-dropdown-item-button v-if="props.row.project_type == '2' && props.row.project_status == 'draft'"
                class="w-100" variant="primary" :type="'submit'"
                v-on:click.prevent="makeReady($event, props.row.id_project, 2)">Conferma</b-dropdown-item-button>
              <b-dropdown-item-button v-if="props.row.project_type == '2' && props.row.project_status == 'ready'"
                class="w-100" variant="primary" :type="'submit'" v-on:click.prevent="makeDraft($event, props.row.id_project, 2)">Metti in
                bozza</b-dropdown-item-button>
            </b-dropdown>
          </div>

        </template>
      </vue-good-table>
    </section>
  </section>
</template>

<script>
import { Requests } from "@/api/requests.js";
import { BButton, BDropdown, BDropdownItemButton } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { BACKEND_BASE_URL } from "@/config";
export default {
  components: {
    VueGoodTable,
    BButton,
    BDropdown,
    BDropdownItemButton
  },

  data: () => ({
    BACKEND_BASE_URL: BACKEND_BASE_URL,
    project_list: [],
    user_token: "",
    columns: [
      {
        label: "ID Progetto",
        field: "id_project",
      },
      {
        label: "Codice Progetto",
        field: "project_code",
      },
      {
        label: "Progetto",
        field: "project_name",
      },
      {
        label: "Cliente",
        field: "project_customer_companyname",
      },
      {
        label: "Data Creazione",
        field: "creation_date",
      },
      {
        label: "Stato",
        field: "status",
      },
      {
        label: "Azioni",
        field: "action",
      },
    ],
  }),

  created() {
    this.user_token = localStorage.getItem("access_token");
    this.getAccountProjects();
  },

  methods: {
    openPdf(event) {
      event.preventDefault();
      event.target.parentElement.parentElement.submit();
    },
    async makeReady(event, id, type) {
      try {
        
        let response;
        if (type == 1) {
          response = await Requests.adminConfirmData(id);
        } else {
          response = await Requests.adminConfirmMaintenanceData(id);
        }
        await this.getAccountProjects()
      } catch (err) {
        console.log(err);
      }
    },
    async makeDraft(event, id, type) {
      try {
        
        let response;
        if (type == 1) {
          response = await Requests.adminDraftData(id);
        } else {
          response = await Requests.adminDraftMaintenanceData(id);
        }
        await this.getAccountProjects()
      } catch (err) {
        console.log(err);
      }
    },
    async getAccountProjects() {
      let data = new FormData();

      data.append("id_account", this.$route.params.id);

      try {
        const response = await Requests.getAccountProjects(data);
        this.project_list = response;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
